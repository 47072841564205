import React from "react";
import { Layout } from "../../layouts/Layout";
import { CHECK_ICON, SERVICES_DETAILS05 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";

const ServicesDetailsPageFive = () => {
  return (
    <Layout breadcrumb={"Products"} title={"Dumbwaiter"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper hideTitle>
        <div className="services-details-wrap">
          <div className="services-details-content">
            <h2 className="title">Dumbwaiter: The Ultimate Solution for Efficient and Reliable Service</h2>
            <p>
            At Al Ehsan Lift, we understand the importance of efficiency and reliability in transporting goods,
            especially in busy environments like restaurants, hotels, and homes. Our Dumbwaiter,
            also known as a Food Lift, is designed to meet these needs,
            providing a convenient and effective way to move items between floors quickly and safely.
            </p>
            <div className="services-details-thumb-two">
              <img src={SERVICES_DETAILS05} alt="" />
            </div>
            <p>
            Our Dumbwaiter operates quietly, ensuring that it does not disturb the ambiance of your establishment.
            This is particularly important in settings like restaurants and hotels, where maintaining a pleasant environment is crucial.
            </p>
            
            
            <div className="sd-inner-wrap-five">
              <div className="row">
                <div className="col-50">
                  <div className="left-side-content">
                    <h4 className="title-two">Our Goal</h4>
                    <p>
                    We enhance workplace efficiency and optimize project space utilization.

                    </p>
                  </div>
                </div>
                <div className="col-50">
                  <div className="right-side-content">
                  
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Safety Features
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Space-Efficient Design
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        High-Quality Construction
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="speed-optimized-wrap">
              <h4 className="title-two">Enhanced Efficiency</h4>
              <p>
              The Dumbwaiter is a vital tool in any multi-story establishment where quick and efficient service is essential.
              It simplifies the process of transporting food, beverages, dishes, and other small items between floors,
              saving time and reducing the workload for staff:
              </p>              
            </div>
            <div>
                <p>
                Fast Transport: The Dumbwaiter ensures swift and smooth movement of items,
                allowing staff to focus on providing excellent service rather than running up and down stairs.
                  </p>
                  </div>
                  <div>
                <p>
                Reduced Labor: By automating the transport process,
                the Dumbwaiter reduces the physical strain on employees, enhancing productivity and efficiency.
                  </p>
                  </div>
            <div className="company-benefit-wrap">
              <h4 className="title-two">High-Quality Construction</h4>
              <p>
              Our Dumbwaiter is built to the highest standards, ensuring durability and long-lasting performance.
              It is constructed using robust materials that can withstand frequent use,
              making it a reliable addition to any commercial or residential setting:
              </p>
              <div>
                <p>
                Stainless Steel: The lift’s interior is made of high-quality stainless steel, which is not only durable but also easy to clean and maintain.
                  </p>
                  </div>
                  <div>
                <p>
                Heavy-Duty Components: The lift is equipped with heavy-duty motors and components that ensure smooth and consistent operation even under heavy loads.
                  </p>
                  </div>
              <div className="speed-optimized-wrap">
              <h4 className="title-two">Safety Features</h4>
              <p>
              Safety is a top priority at Al Ehsan Lift, and our Dumbwaiter is equipped with
              several features to ensure secure and reliable operation:
              </p>              
            </div>
            <div>
                <p>
                Safety Interlocks: Prevents the lift from moving unless the doors are properly closed, ensuring safe use at all times.
                  </p>
                  </div>
                  <div>
                <p>
                Emergency Stop Button: Allows for immediate halting of the lift in case of an emergency, providing an added layer of security.
                  </p>
                  </div>       
                  <div>
                <p>
                Overload Protection: Ensures that the lift does not operate if the load exceeds the maximum capacity, protecting both the lift and the items being transported.
                  </p>
                  </div>       
            <div className="speed-optimized-wrap">
              <h4 className="title-two">Space-Efficient Design</h4>
              <p>
              The Dumbwaiter is designed to be compact and space-efficient,
              making it easy to install in a variety of settings, including tight 
              spaces where traditional lifts might not fit:
              </p>                       
            </div>
            <div>
                <p>
                Flexible Installation: Can be installed in existing buildings with minimal modifications,
                making it a versatile solution for various environments.
                  </p>
                  </div>
                  <div>
                <p>
                Customizable Sizes: Available in different sizes to suit your specific needs,
                whether you require a small lift for a private home or a larger one for a busy restaurant.
                  </p>
                  </div>       
            
              {/* faq */}
              <ServicesDetailsFaq />
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandSeven />
    </Layout>
  );
};

export default ServicesDetailsPageFive;
