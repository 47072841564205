import React, { useEffect, useState } from "react";
import { Layout } from "../../layouts/Layout";
import { BlogPageWrapper } from "../../components/BlogAreas/BlogPageWrapper";
import { Link, useSearchParams } from "react-router-dom";
import {
  BLOG_AVATAR01,
  BLOG_DETAILS01,
  BLOG_DETAILS02,
  CHECK_ICON,
} from "../../lib/assets";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { BlogAuthorInfo } from "../../components/BlogAreas/BlogAuthorInfo";
import { BlogComments } from "../../components/BlogAreas/BlogComments";
import { BlogCommentForm } from "../../components/BlogAreas/BlogCommentForm";
import { BLOG_PAGE_LIST } from "../../mocks/blogs";

const BlogDetailsPage = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [blogDetails, setBlogDetails] = useState({});

  useEffect(() => {

    if (searchParams) {
      console.log(blogDetails)

      let blogID = searchParams.get('BID');
      let findBlog = BLOG_PAGE_LIST.filter((item) => item.id == blogID);
      if (findBlog && findBlog.length > 0)
        setBlogDetails(findBlog[0]);

      console.log(findBlog)
    }

  }, []);

  return (
    <Layout breadcrumb={"Blog Details"} title={"Blog Details"}>
      <BlogPageWrapper>
        {/* image */}
        <div className="blog-details-thumb">
          <img src={blogDetails?.src2} alt="" />
        </div>

        {/* content */}
        <div className="blog-details-content">
          <h2 className="title">{blogDetails?.title}</h2>

          <div className="blog-meta-three">
            <ul className="list-wrap">
            </ul>
          </div>

          <p>
            {blogDetails?.description1}
          </p>
          <p>
            {blogDetails?.description2}
          </p>
          <p>
            {blogDetails?.description3}
          </p>

          <blockquote>
            <p>
              “ Experience the ease and security of online payments with ALEhsan Lift today! ”
            </p>
          </blockquote>



          <div className="bd-content-bottom">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="post-tags">
                  <h5 className="title">Tags:</h5>
                  <ul className="list-wrap">
                    <li>
                      <a href="#">Online Payment</a>
                    </li>
                    <li>
                      <a href="#">Marketing</a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* bottom */}
              <div className="col-md-5">
                <div className="blog-post-share">

                  <ul className="list-wrap">
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* auhor info */}
        <BlogAuthorInfo />

        {/* comments */}
        <BlogComments />

        {/* comment form */}
        <BlogCommentForm />
      </BlogPageWrapper>
    </Layout>
  );
};

export default BlogDetailsPage;
