import React from "react";
import {
  OVERVIEW_IMG01,
  OVERVIEW_IMG02,
  OVERVIEW_IMG_SHAPE,
} from "../../lib/assets";
import { Odometer } from "../Odometer/Odometer";

export const OverviewAreaOne = () => {
  return (
    <section className="overview-area pt-120 pb-120">
      <div
        className="overview-shape"
        data-aos="fade-left"
        data-aos-delay="200"
      ></div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="overview-img-wrap">
              <img src={OVERVIEW_IMG01} alt="" />
              <img src={OVERVIEW_IMG02} alt="" data-parallax='{"x" : 50 }' />
              <img src={OVERVIEW_IMG_SHAPE} alt="" />
              <div className="icon">
                <i className="flaticon-report-1"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="overview-content">
              <div className="section-title-two mb-20 tg-heading-subheading animation-style3">
                <span className="sub-title">FREE CONSULTATION</span>
                <h2 className="title tg-element-title">
                Request a free consultation to gain clarity and make a confident, informed decision.
                </h2>
              </div>
              <p className="info-one">
              We offer a free consultation to help you choose the perfect elevator solution for 
              your home or business. Our experts provide personalized advice, conduct detailed 
              site assessments, and ensure transparent pricing with no hidden fees. 
              From consultation to installation and maintenance, we guide you every step of the way.

              </p>
              <p className="info-two">
              Contact us via phone, email, or our online form to schedule your consultation and receive 
              a tailored proposal. Let us help you elevate your space with our expert advice and 
              top-quality products.
              </p>
              <p className="info-two">
              CALL US TODAY (00971 50 40 90 60 5)
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
