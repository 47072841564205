import React from "react";
import { Layout } from "../../layouts/Layout";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";
import { CHECK_ICON, SD_VIDEO_IMG, SERVICES_DETAILS01 } from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { BrandSeven } from "../../components/Brand/BrandSeven";

const xxx = [1,2,4,5]
const ServicesDetailsPage = () => {
  return (
    <Layout breadcrumb={"Services"} title={"Stairlift"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper  hideTitle>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={SERVICES_DETAILS01} alt="" />
          </div>

          <div className="services-details-content">
            <h2 className="title">
            Premium Stairlift: Safe, Compact, and Reliable
            </h2>

            
            <p>
            At Alehsan Lift, we are committed to providing top-tier elevator solutions,
             and our Stairlift stands as a testament to this promise. 
             Our Stairlift is designed to offer unparalleled safety, convenience,
              and space efficiency, making it an ideal choice for any home or building with a staircase.
            </p>
            <p>
            To underline our commitment to quality and customer satisfaction,
            we offer a two-year warranty on our Stairlift.
            This warranty covers all parts and labor, giving you peace of mind and confidence in your investment.
            Our dedicated service team is always ready to assist with any maintenance or support you might need,
            ensuring that your Stairlift remains in optimal condition.
            </p>

            <div className="sd-inner-wrap">
              <div className="row align-items-center">
                <div className="col-56">
                  <div className="content">
                    <h3 className="title-two">
                    Safety First
                    </h3>
                    <p>
                    Safety is our top priority, and our Stairlift is engineered with this in mind.
                    It is equipped with state-of-the-art safety features, ensuring a secure ride every time.
                    These features include:
                    </p>                
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Built-in safety sensors
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Secure seatbelt
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Smooth start and stop
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Foldable seat
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-44">
                  <div className="thumb">
                    <img src={SD_VIDEO_IMG} alt="" />

                    <VideoPlayerOne
                      src={"https://www.youtube.com/embed/qIPwtod7W3M?si=5WuQcpLP_A23FBL2"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <p>
            Safety sensors detect any obstacles on the stairs, automatically stopping the stairlift to prevent accidents.
            The seatbelt provides extra security, keeping the user safely in place during operation.
            The smooth start and stop mechanism prevents sudden jolts, ensuring a comfortable and safe ride.
            Additionally, the foldable seat maximizes your home space while ensuring safe movement for everyone!
            </p>
            
            <div>
              
            <div>
                  <p>

                  </p>
                </div>
            <h2 className="title-two">Compact Design</h2>
                  <p>
                  One of the standout features of our Stairlift is its compact, single-rail design.
                  This design is not only aesthetically pleasing but also incredibly practical.
                  The single rail is very narrow, allowing the Stairlift to be installed on virtually any staircase,
                  regardless of its shape or size.
                  This ensures that the maximum amount of space is available for other users of the staircase,
                  maintaining the functionality and convenience of your stairs.
                  </p>
                  <h2 className="title-two">Maximum Usable Space</h2>
                  <p>
                  Our Stairlift is designed to occupy minimal space, ensuring that the staircase remains accessible for everyone.
                  The narrow rail and sleek design mean that the Stairlift can be installed close to the wall,
                  leaving ample room for others to use the stairs comfortably.
                  This is particularly important in homes or buildings with high foot traffic, where maintaining accessibility is crucial.
                  </p>
              </div>
            <div>
              <p>
              At Alehsan Lift, we believe that mobility solutions should enhance your lifestyle without compromising on safety or space.
              Our Stairlift is designed with this philosophy in mind, providing you with a reliable, safe, and space-efficient solution
              for navigating stairs. Contact us today to learn more about our Stairlift and how it can improve your home or building.
              </p>
            </div>
              
                
                
            <div className="company-benefit-wrap">
            
              {/* faq accordion */}
              <ServicesDetailsFaq />
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandSeven />
    </Layout>
  );
};

export default ServicesDetailsPage;
