import React from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  SD_WORKING_IMG03,
  SD_WORKING_IMG04,
  SERVICES_DETAILS04,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";

const ServicesDetailsPageFour = () => {
  return (
    <Layout breadcrumb={"Products"} title={"Vacuum Lift"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          <div className="services-details-content">
            <div className="sd-inner-wrap-four">
              <div className="row align-items-center">
                <div className="col-55">
                  <div className="thumb">
                    <img src={SERVICES_DETAILS04} alt="" />
                  </div>
                </div>
                <div className="col-45">
                  <div className="content">
                    <h2 className="title">
                    Vacuum Lift: Innovation, Efficiency, and Elegance
                    </h2>
                    <p>
                    At Al Ehsan Lift, we are dedicated to offering cutting-edge elevator solutions that combine innovation,
                    efficiency, and elegance. Our Vacuum Lift is a perfect example of this commitment,
                    providing a unique and advanced elevator experience that enhances the functionality
                    and aesthetics of any home or building.
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Innovative Design
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Efficiency and Space-Saving
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Safety and Reliability
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        Eco-Friendly Technology
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="services-details-list-three">
              <ul className="list-wrap">
                <li>
                  <div className="icon">
                    <i className="flaticon-family"></i>
                  </div>
                  <div className="content">
                    <h4 className="title">Made In UAE</h4>
                    <p>
                    We support local industry which delivering high quality and efficiency.

                    </p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <i className="flaticon-winner"></i>
                  </div>
                  <div className="content">
                    <h4 className="title">Certified</h4>
                    <p>
                    Quality tests and certifications are crucial for guaranteeing the best product.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <p>
            The Vacuum Lift is a marvel of modern engineering, utilizing air pressure to transport passengers between floors.
            Its sleek, cylindrical design sets it apart from traditional elevators, adding a touch of contemporary elegance to your space.
            The transparent polycarbonate walls offer a panoramic view, creating a sense of openness and modernity.
            </p>
            <div className="industrial-working-wrap mt-50">
              <h2 className="title-two">
              Elegance and Comfort
              </h2>
              <p className="info-one">
              The Vacuum Lift offers a luxurious and comfortable ride, making it a stylish addition to any home or building.
              The quiet operation and smooth ride ensure a pleasant experience for passengers,
              while the transparent design provides a sense of spaciousness and elegance.
              </p>
              <div className="row">
                <div className="col-md-6">
                  <img src={SD_WORKING_IMG03} alt="" />
                </div>
                <div className="col-md-6">
                  <img src={SD_WORKING_IMG04} alt="" />
                </div>
              </div>
              <div>
              <h2 className="title-two">
              Safety and Reliability
              </h2>
              <p className="info-one">
              Safety is a top priority at Al Ehsan Lift, and our Vacuum Lift is designed with this in mind.
              The advanced air pressure technology ensures a smooth and safe ride, while the multiple safety
              features provide peace of mind for all users:
              </p>
              <div>
                <p>
                 Emergency Brake System: In the event of a power outage or malfunction,
                 the emergency brake system activates, ensuring the lift comes to a safe and controlled stop.
                </p>
              </div>
              <div>
                <p>
                 Air Pressure Monitoring: Continuous monitoring of air pressure levels ensures optimal performance
                 and safety at all times.
                </p>
              </div>
              <div>
                <p>
                 Backup Power Supply: The lift is equipped with a backup power supply to ensure it
                 remains operational even during power outages.
                </p>
              </div>
              </div>
              <div>
              <h2 className="title-two">
              Efficiency and Space-Saving
              </h2>
              <p className="info-one">
              One of the standout features of the Vacuum Lift is its efficiency in terms of space and installation.
              Unlike conventional elevators that require a machine room, pit, and shaft,
              the Vacuum Lift can be installed with minimal structural modifications.
              This makes it an ideal solution for existing buildings and homes where space is at a premium.
              </p>
              <div>
                <p>
                Minimal Footprint: The compact design of the Vacuum Lift ensures that it occupies minimal floor space,
                allowing you to maximize the usable area in your home or building.
                </p>
              </div>
              <div>
                <p>
                Quick Installation: The modular construction and straightforward installation process mean
                that your Vacuum Lift can be up and running in a matter of days, not weeks.
                </p>
              </div>
              </div>

              <div>
              <h2 className="title-two">
              Eco-Friendly Technology
              </h2>
              <p className="info-one">
              The Vacuum Lift is also an environmentally friendly choice, as it consumes significantly less energy compared
              to traditional elevators. The air pressure system requires no lubricants or harmful chemicals,
              making it a sustainable and green solution for vertical transportation.
              </p>
              </div>
              
            </div>

            {/* faq */}
            <ServicesDetailsFaq />
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandSeven />
    </Layout>
  );
};

export default ServicesDetailsPageFour;
