import React from "react";
import { Link } from "react-router-dom";

export const TeamAreaOneItem = ({ member }) => {
  return (
    <div className="team-item">
      <div className="team-thumb">
        <img src={member.src} alt="" />

        <div className="team-social">
        <ul className="list-wrap">
                    <li>
                        <a href="https://youtube.com/@alehsanliftstrading?feature=shared">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.tiktok.com/@alehsanlifts?_t=8oZ3NTAMrye&_r=1">
                          <i className="fab fa-tiktok"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/alehsan_lifts_trading?igsh=MTYxOHIzZ3JxcjVyMg==">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/alehsan-lifts-trading/">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
        </div>
      </div>

      <div className="team-content">
        <h2 className="title">
          <Link to={member.href}>{member.title} </Link>
        </h2>

        <span>{member.designation} </span>
      </div>
    </div>
  );
};
