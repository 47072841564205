import React from "react";
import { SERVICES_ONE_LIST } from "../../mocks/services";
import { ServiceOneItem } from "./ServiceOneItem";

export const ServiceFive = () => {
  const list = [...SERVICES_ONE_LIST, ...SERVICES_ONE_LIST];
  list.length = 3;

  return (
    <section className="services-area-five inner-services-bg">
      <div className="container">     
        <div className="row justify-content-center">
          {SERVICES_ONE_LIST.map((service) => (
            <div key={service.id} className="col-lg-4 col-md-6 col-sm-10">
              <ServiceOneItem service={service} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
