import React, { useState, useEffect } from "react";
import { Layout } from "../../layouts/Layout";
import {
  CHECK_ICON,
  PROJECT_DETAILS01,
  PROJECT_DETAILS02,
} from "../../lib/assets";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { useParams, useSearchParams } from "react-router-dom";
import { SERVICES_ONE_LIST } from "../../mocks/services";

const ProjectDetailsPage = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [projectDetail, setProjectDetail] = useState({});
  useEffect(() => {


    if (searchParams) {
      let projectID = searchParams.get('PDID');
      let findProject = SERVICES_ONE_LIST.filter((item) => item.id == projectID);
      if (findProject && findProject.length > 0)
        setProjectDetail(findProject[0])
    }

  }, []);

  return (
    <Layout breadcrumb={projectDetail?.projectLocation} title={"Completed Projects"}>
      {/* project-details-area */}
      <section className="project-details-area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="project-details-wrap">
                <div className="row">
                  {/* image */}
                  <div className="col-71">
                    <div className="project-details-thumb">
                      <img src={projectDetail.projectHeaderImg} alt="" />
                    </div>
                  </div>

                  {/* project details info */}
                  <div className="col-29">
                    <div className="project-details-info">
                      <h4 className="title">Project Information</h4>
                      <ul className="list-wrap">
                        <li>
                          <span>Location:</span>{projectDetail.projectLocation}
                        </li>
                        <li>
                          <span>Date:</span>{projectDetail.projectDate}
                        </li>

                        <li>
                          <span>Capacity:</span> {projectDetail.projectCapacity}
                        </li>
                        <li>
                          <span>No. Floor:</span> {projectDetail.projectFloor}
                        </li>
                        <li>
                          <span>Speed: :</span> {projectDetail.projectSpeed}
                        </li>

                        <li className="social">
                          <ul className="list-wrap">
                            <li>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="project-details-content">
                  <h2 className="title">{projectDetail?.subtitle}</h2>
                  <p>
                    {projectDetail.description}
                  </p>
                  <div className="pd-optimized-wrap">
                  </div>
                  <div className="pd-inner-wrap">
                    <div className="row align-items-center">
                      <div className="col-41">
                        <div className="content">
                          <h3 className="title-two">
                            For This Project
                          </h3>
                          <p className="info-one">
                            The Customer’s Requirements Include:
                          </p>
                          <ul className="list-wrap">
                            {projectDetail?.customerRequirements?.map((item, index) => {
                              return (<>
                                <li>
                                  <img src={CHECK_ICON} alt="" />
                                  {item}
                                </li>
                              </>)
                            })}
                          </ul>
                        </div>
                      </div>

                      <div className="col-59">
                        <div className="thumb">
                          <img src={projectDetail?.projectFooterImg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProjectDetailsPage;
