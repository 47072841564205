import { TESTI_AVATAR01, TESTI_AVATAR02 } from "../lib/assets";

export const TESTIMONIAL_LIST_TWO = [
  {
    id: 1,
    text: "We are extremely happy with the chair lift in terms of the quality, their service, on time installation and last but not least their professionalism...I would not hesitate even a single second to recommend them for their jobs. A very satisfied customer.",
    src: TESTI_AVATAR01,
    title: "Radhika Gadde",
  
  },
  {
    id: 2,
    text: "Getting the chair from AL EHSAN LIFT is the best decision we made.  The whole experience was smooth, from the first visit of the Representative  until final delivery and installation! I will certainly recommend them to any one. Good job and wish you best",
    src: TESTI_AVATAR02,
    title: "Neeta Merchant",
    
  },
  {
    id: 3,
    text: "After two years of experience with the company, I can rate the company as one of the best companies I have dealt with. The project was completed in the best way and in the fastest time. Also, maintenance services were always available and of the highest quality.",
    src: TESTI_AVATAR01,
    title: "Rashid Ali",
   
  },
];
