import {
  BLOG_AVATAR01,
  BLOG_AVATAR02,
  BLOG_AVATAR03,
  BLOG_AVATAR04,
  BLOG_AVATAR05,
  BLOG_AVATAR06,
  BLOG_DETAILS_HEADER_1,
  BLOG_DETAILS_HEADER_2,
  BLOG_DETAILS_HEADER_3,
  BLOG_IMG01,
  BLOG_IMG02,
  BLOG_IMG03,
  H2_BLOG_IMG01,
  H2_BLOG_IMG02,
  H2_BLOG_IMG03,
  H3_BLOG_IMG01,
  H3_BLOG_IMG02,
  H3_BLOG_IMG03,
  H3_BLOG_IMG04,
  H3_BLOG_IMG05,
  H3_BLOG_IMG06,
  H4_BLOG_IMG01,
  H4_BLOG_IMG02,
  H4_BLOG_IMG03,
  RC_POST01,
  RC_POST02,
  RC_POST03,
  RC_POST04,
} from "../lib/assets";

export const BLOGS_LIST_TWO = [
  {
    id: 1,
    href: "/blog-details",
    src: H2_BLOG_IMG01,
    src2: H3_BLOG_IMG01,
    src3: H4_BLOG_IMG01,
    src4: BLOG_IMG01,
    tag: "Development",
    tag2: "Employees",
    tag3: "Business ",
    tagHref: "/blog",
    title: "ONLINE PAYMENT",
    subtitle:
      "We've Simplified And Secured The Payment Process, Making It A Breeze For Our Customers To Pay Using The Payment Link!",
  },
  {
    id: 2,
    href: "/blog-details",
    src: H2_BLOG_IMG02,
    src2: H3_BLOG_IMG02,
    src3: H4_BLOG_IMG02,
    src4: BLOG_IMG02,
    tag: "Business",
    tag2: "Employees",
    tag3: "Development",
    tagHref: "/blog",
    title: "STRATEGIC PARTNERSHIPS xxx",
    subtitle:
      "We've Forged Strategic Partnerships With The Leading Elevator Manufacturers To Offer You Comprehensive Solutions And The Highest Quality Products.",
  },
  {
    id: 3,
    href: "/blog-details",
    src: H2_BLOG_IMG03,
    src2: H3_BLOG_IMG03,
    src3: H4_BLOG_IMG03,
    src4: BLOG_IMG03,
    tag: "Employees",
    tag2: "Business",
    tag3: "Development",
    tagHref: "/blog",
    title: "TRAINING",
    subtitle:
      "We're Dedicated To Enhancing Our Employees' Experience By Offering Educational And Cultural Courses About The Rich Culture And Society Of The UAE.",
  },
];

export const BLOG_CATEGORIES = [
  {
    label: "Development",
    value: "01",
  },
  {
    label: "Business",
    value: "01",
  },
  {
    label: "Employees",
    value: "01",
  },

];

export const RECENT_BLOGS = [
  {
    id: 1,
    createdAt: "22 Jan, 2023",
    href: "/blog-details",
    src: RC_POST01,
    title: "Whale Be Raised Must Be In A Month",
  },
  {
    id: 2,
    createdAt: "22 Jan, 2023",
    href: "/blog-details",
    src: RC_POST02,
    title: "Whale Be Raised Must Be In A Month",
  },
  {
    id: 3,
    createdAt: "22 Jan, 2023",
    href: "/blog-details",
    src: RC_POST03,
    title: "Whale Be Raised Must Be In A Month",
  },
  {
    id: 4,
    createdAt: "22 Jan, 2023",
    href: "/blog-details",
    src: RC_POST04,
    title: "Whale Be Raised Must Be In A Month",
  },
];

export const BLOG_TAGS = ["Home Design", "Consultancy", "Sales", "Elevator"];

export const BLOG_PAGE_LIST = [
  {
    id: 1,
    title: "ALEhsan Lift Introduces Online Payment",
    subtitle: "Experience the ease and security of online payments with ALEhsan Lift Trading today!",
    description1: "At ALEhsan Lift, we're committed to making your experience as smooth and convenient as possible. That's why we're excited to announce our latest development: an online payment system designed to simplify and secure your transactions.",
    description2: "With our new online payment option, you can now easily pay for your elevator products and services using a secure payment link. This system ensures that your payments are processed quickly and safely, providing you with peace of mind and a hassle-free experience.",
    description3: "At ALEhsan Lift, we believe in leveraging technology to enhance our services and improve customer satisfaction. Our new online payment system is just one of the many ways we're working to provide you with the best service possible.",
    tag: "Development",
    src: H3_BLOG_IMG01,
    src2: BLOG_DETAILS_HEADER_1,
    href: "/blog-details",
    tagHref: "/blog",
  },
  {
    id: 2,
    title: "Strategic Partnerships for Superior Solutions",
    subtitle: "Experience the benefits of our strategic partnerships and elevate your expectations with ALEhsan Lift today!",
    description1: "At ALEhsan Lift, we are dedicated to providing our customers with the best elevator solutions available. To achieve this, we have forged strategic partnerships with the leading elevator manufacturers in the industry. These collaborations allow us to offer comprehensive solutions and the highest quality products to meet all your elevator needs.",
    description2: "By partnering with top manufacturers, we ensure that our customers benefit from the latest advancements in elevator technology, superior craftsmanship, and reliable performance. Whether you're looking for a new installation, modernization, or maintenance services, our strategic alliances enable us to deliver unparalleled quality and service.",
    description3: "At ALEhsan Lift, our commitment to excellence drives us to continuously seek out the best partners and products for our customers. With our strong network of industry leaders, you can trust that you are receiving the most advanced and dependable elevator solutions on the market.",
    tag: "Business",
    src: H3_BLOG_IMG02,
    src2: BLOG_DETAILS_HEADER_2,
    href: "/blog-details",
    tagHref: "/blog",
  },
  {
    id: 3,
    title: "Employee Experience through Cultural Training",
    subtitle: "At ALEhsan Lift, we are proud to invest in our employees' growth and to celebrate the unique culture of the UAE",
    description1: "At ALEhsan Lift, we believe that a well-rounded employee experience is essential for both personal and professional growth. That's why we are dedicated to offering educational and cultural courses that explore the rich culture and society of the UAE.",
    description2: "Our training programs are designed to provide our employees with a deeper understanding of the UAE's traditions, history, and societal norms. By fostering this cultural awareness, we aim to create a more inclusive and respectful workplace environment.",
    description3: "Through these courses, our employees not only enhance their knowledge but also develop a greater appreciation for the diverse cultural landscape in which we operate. This initiative reflects our commitment to continuous learning and the holistic development of our team members.",
    tag: "Employee",
    src: H3_BLOG_IMG03,
    src2: BLOG_DETAILS_HEADER_3,
    href: "/blog-details",
    tagHref: "/blog",
  }
];
