import React from "react";
import { W_LOGO } from "../../lib/assets";
import { Link } from "react-router-dom";

export const FooterThree = () => {
  return (
    <footer>
      <div className="footer-area-three">
        <div className="footer-top-three">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-7">
                <div className="footer-widget">
                  <div className="fw-logo">
                    <Link to="/">
                      <img src={W_LOGO} alt="" />
                    </Link>
                  </div>
                  <div className="footer-content">
                    <p>
                      When an unknown printer took a galley of type aawer awtnd
                      scrambled it to make a type specimen book.
                    </p>
                    <div className="footer-social footer-social-three">
                      <ul className="list-wrap">
                      <li>
                        <a href="https://youtube.com/@alehsanliftstrading?feature=shared">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.tiktok.com/@alehsanlifts?_t=8oZ3NTAMrye&_r=1">
                          <i className="fab fa-tiktok"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/alehsan_lifts_trading?igsh=MTYxOHIzZ3JxcjVyMg==">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/alehsan-lifts-trading/">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div hidden className="col-lg-2 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Menu</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/about">Company</Link>
                      </li>
                      <li>
                        <Link to="/contact">Careers</Link>
                      </li>
                      <li>
                        <Link to="/contact">Press media</Link>
                      </li>
                      <li>
                        <Link to="/blog">Our Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Quick Links</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/contact">Our Products</Link>
                      </li>
                      <li>
                        <Link to="/services">Latest Projects</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-7">
                <div className="footer-widget">
                  <h4 className="fw-title">Contact</h4>
                  <div className="footer-info">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-pin"></i>
                        </div>
                        <div className="content">
                          <p>Dubai, United Arab Emirates</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-phone-call"></i>
                        </div>
                        <div className="content">
                          <a href="tel:0504090605">+971 50 40 90 60 5</a>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-clock"></i>
                        </div>
                        <div className="content">
                          <p>
                            Mon – Sat: 9 am – 5 pm, <br /> Sunday:{" "}
                            <span>CLOSED</span>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-three">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text-two text-center">
                  <p>Copyright © AL EHSAN LIFT | All Right Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
