import React from "react";

export const ServicesDetailsFaq = () => {
  return (
    <div className="accordion-wrap-three">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              24/7 Service and Support 
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              At Alehsan Lift, our commitment to excellence goes beyond providing top-tier elevator solutions.
              We believe that exceptional service is just as important as the quality of our products.
              That’s why we offer 24/7 service and support to ensure that our clients receive the assistance they need, anytime and anywhere.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Expert Care and Precision
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              At Alehsan Lift, our commitment to exceptional customer care is at the core of everything we do.
              We understand that selecting the right elevator and ensuring its seamless installation are crucial
              steps in enhancing the functionality and value of your space. 
              That’s why we offer expert guidance and efficient installation services to ensure a smooth and satisfactory experience for every client.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Why Choose Alehsan Lifts?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              When it comes to selecting the right elevator solutions for your needs, Al Ehsan Lift stands out as the premier choice. Here’s why:
              </p>
              <p>
              1)
              </p>
              <p>
              Wide Range of Products:
              At Al Ehsan Lift, we offer an extensive range of elevator products designed to meet a variety of needs and preferences.
              Whether you're looking for a home passenger lift, stairlift, industrial lift, dumbwaiter, or vacuum lift, 
              we have the perfect solution for you. Our diverse product line ensures that you find the ideal elevator to 
              fit your specific requirements, making us your one-stop shop for all your vertical mobility needs.              
              </p>
              <p>
              2)
              </p>
              <p>
              High Quality:
              Quality is at the heart of everything we do. 
              Our elevators are sourced from top manufacturers and are built to the highest standards. 
              We use advanced technology and rigorous quality control measures to ensure that every product we offer is reliable,
              durable, and performs exceptionally. When you choose Al Ehsan Lift, you are investing in an elevator that stands the test of time.          
              </p>
              <p>
              3)
              </p>
              <p>
              Best After-Sale Service Support:
              We are committed to providing exceptional after-sale service support to ensure that your elevator experience
              is seamless and worry-free. Our dedicated support team is available around the clock to address any questions
              or issues that may arise. From routine maintenance to emergency repairs,
              we are here to keep your elevator running smoothly and efficiently.            
              </p>
              <p>
              4)
              </p>
              <p>
              Reasonable Price:
              At Al Ehsan Lift, we believe that high quality should come at a reasonable price.
              We offer competitive pricing on all our products and services, ensuring that you get the best value for your investment. 
              Our transparent pricing and commitment to cost-effectiveness make us a preferred choice for those seeking quality elevator
              solutions without breaking the bank.              
              </p>
              <p>
              .
              </p>
              <p>
              Contact us today to learn more about our Stairlift and how it can improve your home or building.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
