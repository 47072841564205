import React from "react";
import { BLOG_PAGE_LIST, BLOGS_LIST_TWO } from "../../mocks/blogs";
import { BlogAreaTwoItem } from "./BlogAreaTwoItem";

export const BlogAreaTwo = () => {
  return (
    <section className="blog-area-two blog-bg-two">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title-two text-center mb-50 tg-heading-subheading animation-style3">
              <span className="sub-title">News & Blogs</span>
              <h2 className="title tg-element-title">
                Read Our Latest Updates vvv
              </h2>
              <p>
                Curious To Dive Into Our Latest Exciting Updates And Activities?
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {
            BLOG_PAGE_LIST.reverse().slice(0,3).map((blog) => {
              return (
                <div key={blog.id} className="col-lg-4 col-md-6 col-sm-10">
                  <BlogAreaTwoItem blog={blog} />
                </div>)
            })
          }
        </div>
      </div>
    </section>
  );
};
