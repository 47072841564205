import {
  H2_SERVICES_IMG01,
  H2_SERVICES_IMG02,
  H2_SERVICES_IMG03,
  H2_SERVICES_IMG04,
  H4_SERVICES_IMG01,
  H4_SERVICES_IMG02,
  H4_SERVICES_IMG03,
  H4_SERVICES_IMG04,
  PROJECT_IMG_DUBAI01_footer,
  PROJECT_IMG_DUBAI01_header,
  PROJECT_IMG_KSA01_footer,
  PROJECT_IMG_KSA01_header,
  PROJECT_IMG_SHARJAH01_footer,
  PROJECT_IMG_SHARJAH01_header,
  SERVICES_IMG01,
  SERVICES_IMG02,
  SERVICES_IMG03,
  SERVICES_ITEM_SHAPE,
} from "../lib/assets";

export const SERVICES_TWO_LIST = [
  {
    id: 1,
    src: H2_SERVICES_IMG01,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-piggy-bank",
    href: "/services-details",
    homehref:"services-details-2",
    title: "Passenger Lift",
    subtitle: "Perfect For Every Home and Building.",
  },
  {
    id: 2,
    src: H2_SERVICES_IMG02,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-calculator",
    href: "/services-details",
    homehref:"services-details-4",
    title: "Panoramic Lift",
    subtitle: "Perfect For Prefabricated Homes, Narrow Spaces.",
  },
  {
    id: 3,
    src: H2_SERVICES_IMG03,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-money",
    href: "/services-details",
    homehref:"services-details",
    title: "Stair Lift (Chair Lift)",
    subtitle: "Comfort Chair, Perfect For POD And The Elderly.",
  },
  {
    id: 4,
    src: H2_SERVICES_IMG04,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-layers",
    href: "/services-details",
    homehref:"services-details-5",
    title: "Service Lift",
    subtitle: "Perfect For Food and Sterile Transport.",
  },
];

export const SERVICES_THREE_LIST = [
  {
    id: 1,
    src: H4_SERVICES_IMG01,
    icon: "flaticon-healthcare",
    href: "/services-details",
    title: "Health Insurance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 2,
    src: H4_SERVICES_IMG02,
    icon: "flaticon-protection",
    href: "/services-details",
    title: "Vehicle Insurance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 3,
    src: H4_SERVICES_IMG03,
    icon: "flaticon-ship",
    href: "/services-details",
    title: "Cargo Insurance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 4,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-house",
    href: "/services-details",
    title: "Fire Insurance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
];

export const SERVICES_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-business-presentation",
    href: "/services-details",
    title: "Marketing Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 2,
    icon: "flaticon-rocket",
    href: "/services-details",
    title: "SEO Consultancy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 3,
    icon: "flaticon-mission",
    href: "/services-details",
    title: "Target Audience",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
];

export const SERVICES_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-briefcase",
    href: "/new-project-details?PDID=1",
    src: SERVICES_IMG01,
    projectLocation: 'Saudi Arabia',
    projectDetail: '750 KG / 5 Floor / 1.0 m/s',
    projectDate: 'Jun 2024',
    projectCapacity: '750 KG',
    projectFloor: '5',
    projectSpeed: '1.0 m/s',
    projectHeaderImg:PROJECT_IMG_KSA01_header,
    projectFooterImg:PROJECT_IMG_KSA01_footer,
    title: "KSA - DAMMAM",
    subtitle: "Elevators for Saudi Arabia Hotel",
    description: 'Our client is a hotel group located in Saudi Arabia, they have a new resort hotel project in Dammam, the hotel has a total of 5 floors high, needs to install 8 passenger elevators, the project started in 2022, 2023 is officially open for business. After many comparisons, the customer finally chose to cooperate with Dazen Elevator, because Dazen can meet their needs well in terms of both quality and price, and it has a local installation team. All the elevators have passed CE certification',
    customerRequirements: ['8 Sets of Hotel Passenger Elevators', '5 floor height, machine room less', '2-year warranty']
  },
  {
    id: 2,
    icon: "flaticon-taxes",
    href: "/new-project-details?PDID=2",
    src: SERVICES_IMG02,
    projectLocation: 'DUBAI',
    projectDetail: '750 KG / 7 Floor / 1.0 m/s',
    projectDate: 'DEC 2023',
    title: "UAE - DUBAI",
    projectCapacity: '750 KG',
    projectFloor: '7',
    projectSpeed: '1.0 m/s',
    projectHeaderImg:PROJECT_IMG_DUBAI01_header,
    projectFooterImg:PROJECT_IMG_DUBAI01_footer,
    subtitle: "Elevator Modernization For A University In Dubai",
    description: 'We are excited to announce that we have completed an elevator modernization project for a university in Dubai. Our dedicated team completely upgraded the elevator traction machine, control system, COP, LOP, disabled operation panel, car door, and landing door.These improvements have significantly boosted the elevator’s safety and efficiency.',
    customerRequirements: ['Replacing the control system ', 'Replacing a new Torindrive traction machine', 'Customizing the COP with the customer’s logo', 'Adding a disabled operation panel', 'Replacing all car doors and landing doors with brand-new ones']
  },
  {
    id: 3,
    icon: "flaticon-money",
    href: "/new-project-details?PDID=3",
    src: SERVICES_IMG03,
    projectLocation: 'SHARJAH',
    projectDetail: '450 KG / 8 Floor / 1.0 m/s',
    projectDate: 'FEB 2024',
    projectCapacity: '450 KG',
    projectFloor: '8',
    projectSpeed: '1.0 m/s',
    projectHeaderImg:PROJECT_IMG_SHARJAH01_header,
    projectFooterImg:PROJECT_IMG_SHARJAH01_footer,
    title: "UAE - SHARJAH",
    subtitle: "Elevator Project For Hospital In Sharjah",
    description: 'We are pleased to announce that the two elevators purchased by the Hospital in Sharjah have been installed and are working well.',
    customerRequirements: ['Montanari traction machine' , 'Arabic language voice announcement','Fire door E120','Disability control box']
  }
];
