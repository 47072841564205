import React from "react";

export const ContactAreaMap = () => {
  return (
    <div className="contact-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d1296.3618194938763!2d55.1441486!3d25.0742778!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDA0JzI3LjQiTiA1NcKwMDgnMzQuMyJF!5e1!3m2!1sar!2sae!4v1723294339995!5m2!1sar!2sae"
        allowfullscreen
        loading="lazy"
        title="contact map"
      ></iframe>
    </div>
  );
};
