import React, { useEffect, useState } from "react";
import { Layout } from "../../layouts/Layout";
import { BlogPageWrapper } from "../../components/BlogAreas/BlogPageWrapper";
import { BLOG_PAGE_LIST } from "../../mocks/blogs";
import { Link, useSearchParams } from "react-router-dom";

const BlogPage = () => {

  return (
    <Layout breadcrumb={"Blog"} title={"Latest Blog"}>
      <BlogPageWrapper>
        <div className="blog-post-wrap">
          {/* list */}
          <div className="row">
            {BLOG_PAGE_LIST.map((blog) => (
              <div key={blog.id} className="col-md-6">
                <div className="blog-post-item-two">
                  <div className="blog-post-thumb-two">
                    <Link to={`${blog.href}?BID=${blog.id}`}>
                      <img src={blog.src} alt="" />
                    </Link>

                    <Link to={blog.tagHref} className="tag tag-two">
                      {blog.tag}
                    </Link>
                  </div>

                  <div className="blog-post-content-two">
                    <h2 className="title">
                      <Link to={`${blog.href}?BID=${blog.id}`}>{blog.title}</Link>
                    </h2>
                    <p>{blog.subtitle}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </BlogPageWrapper>
    </Layout>
  );
};

export default BlogPage;
