import React from "react";
import { Layout } from "../../layouts/Layout";
import { ServicesDetailsFaq } from "../../components/ServicesDetails/ServicesDetailsFaq";
import {
  SD_WORKING_IMG01,
  SD_WORKING_IMG02,
  SERVICES_DETAILS02,
} from "../../lib/assets";
import { ServicesDetailsWrapper } from "../../components/ServicesDetails/ServicesDetailsWrapper";
import { BrandSeven } from "../../components/Brand/BrandSeven";

const ServicesDetailsPageTwo = () => {
  return (
    <Layout breadcrumb={"Products"} title={"Home Lift"}>
      {/* services-details-area */}
      <ServicesDetailsWrapper>
        <div className="services-details-wrap">
          <div className="services-details-content">
            <h2 className="title">
            Home Passenger Lift: Safe, Quiet, and Space-Efficient
            </h2>
            <p>
            At Alehsan Lift, we pride ourselves on providing innovative and high-quality elevator solutions
            that enhance the comfort and accessibility of your home.
            Our Home Passenger Lift is designed to offer maximum safety, quiet operation,
            and efficient use of space, making it the perfect addition to any residence.
            </p>
            <div className="sd-inner-wrap-two">
              <div className="row align-items-center">
                <div className="col-58">
                  <div className="thumb">
                    <img src={SERVICES_DETAILS02} alt="" />
                  </div>
                </div>
                <div className="col-42">
                  <div className="services-details-list">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-business-presentation"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">High Quality</h5>
                          <p>
                          We care about the details to ensure the best quality.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-investment"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">Safe</h5>
                          <p>
                          We care about your family and ensure safe movement up and down with our elevator.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-taxes"></i>
                        </div>
                        <div className="content">
                          <h5 className="title">24/7</h5>
                          <p>
                          24/7 services, ensuring the ease of our support.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="industrial-working-wrap">
              <h2 className="title-two">Ideal for Existing Buildings
                </h2>
              <p className="info-one">
              Our Home Passenger Lift is specifically designed to be easily installed in existing buildings.
              Whether you live in a historic home or a modern residence,
              our lift can be seamlessly integrated without the need for extensive modifications.
              This adaptability makes it a perfect choice for homeowners looking to enhance their home’s accessibility and value.
              </p>
              <div className="row">
                <div className="col-md-6">
                  <img src={SD_WORKING_IMG01} alt="" />
                </div>
                <div className="col-md-6">
                  <img src={SD_WORKING_IMG02} alt="" />
                </div>
              </div>
              <div>
              <p> </p>
              </div>
              <div>
              <h2 className="title-three">Minimal Pit Requirement

                </h2>
              <p className="info-two">
              One of the standout features of our Home Passenger Lift is its minimal pit requirement.
              Unlike traditional lifts that require a deep pit for installation, our lift needs less than 12 cm.
              This makes it an ideal choice for existing buildings where deep pits might not be feasible.
              The shallow pit requirement ensures easier and faster installation, reducing disruption to your home.
              </p>
              </div>
              <div>
              <p> </p>
              </div>
              <div>
              <h2 className="title-three">Maximum Inside Cabin Space
                </h2>
              <p className="info-two">
              Space efficiency is a key consideration in our Home Passenger Lift design.
              The lift is engineered to maximize the inside cabin space, providing a comfortable and spacious environment for passengers.
              Despite its compact exterior dimensions, the interior is designed to offer ample room,
              ensuring a pleasant and accommodating experience for all users.
              </p>  
              </div> 
              <div>
              <p> </p>
              </div>
              <div>
              <h2 className="title-three">Unparalleled Safety
                </h2>
              <p className="info-two">
              Safety is paramount when it comes to our Home Passenger Lift.
              We incorporate numerous safety features to ensure a secure and reliable ride for all users:
              </p>
              <div>
              <p> </p>
              </div>
              <div>
              <p> 1) Advanced safety sensors: These sensors detect any obstacles, ensuring the lift stops automatically to prevent accidents.
              </p>
              
              </div>  
              <div>
              <p> 2) Emergency alarm system: In the unlikely event of a malfunction, the built-in alarm system alerts others, providing an added layer of security.
              </p>
              </div>  
              <div>
              <p> 3) Smooth and gentle operation: The lift is designed to start and stop smoothly, minimizing any jolts and providing a comfortable ride.
              </p>
              </div>
              <div>
              <p> </p>
              </div>
              <div>
              <p>At Alehsan Lift, we understand the importance of accessibility, safety, and comfort in your home.
                Our Home Passenger Lift is designed with these priorities in mind, offering a reliable, quiet,
                and space-efficient solution for your vertical mobility needs.
                Contact us today to learn more about our Home Passenger Lift and how it can enhance your home. 
                </p>
              </div>
              </div> 
              {/* faq accordion */}
              <ServicesDetailsFaq />
            </div>
          </div>
        </div>
      </ServicesDetailsWrapper>

      {/* brand-area */}
      <BrandSeven />
    </Layout>
  );
};

export default ServicesDetailsPageTwo;
