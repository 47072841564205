import React, { useState } from "react";
import { PRICING_SHAPE } from "../../lib/assets";
import { PRICING_LIST_TWO } from "../../mocks/Pricing";
import { PricingAreaTwoItem } from "./PricingAreaTwoItem";
import { PricingTab } from "./PricingTab";

export const PricingAreaTwo = () => {
  const [isMonthly, setIsMonthly] = useState(true);

  return (
    <></>
  );
};
