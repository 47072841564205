import React from "react";
import { Link } from "react-router-dom";
import {
  INNER_ABOUT_IMG05,
  INNER_ABOUT_SHAPE01,
  INNER_ABOUT_SHAPE02,
} from "../../lib/assets";

export const AboutEleven = () => {
  return (
    <section className="about-area-eleven">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9 order-0 order-lg-2">
            <div className="about-img-wrap-eleven">
              <img src={INNER_ABOUT_IMG05} alt="" />
              <img src={INNER_ABOUT_SHAPE01} alt="" className="shape-one" />
              <img src={INNER_ABOUT_SHAPE02} alt="" className="shape-two" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content-eleven">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title">
                  Best Elevator <br />
                  AL Ehsan Lifts Trading
                </h2>
              </div>

              <p>
              We established in 2016 in UAE. From humble beginnings, we have grown to become a trusted name in the elevator industry.
              Our journey began with a vision to provide reliable and innovative elevator solutions to meet the diverse needs of our clients. 
              Over the years, we have expanded our offerings and capabilities, continually adapting to the latest technologies and industry standards. 

              </p>

              <div className="about-list-two">
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-arrow-right"></i>Passenger Lift
                  </li>
                  <li>
                    <i className="fas fa-arrow-right"></i>Industrial Lift
                  </li>
                  <li>
                    <i className="fas fa-arrow-right"></i>Service Lift
                  </li>
                </ul>
              </div>

              <Link to="/services-details" className="btn btn-three">
                Explore Our Products
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
